import { PaymentModel } from "@getsupertab/tapper-sdk";
import { PurchaseStatus, TabStatus } from "../../../tapper-browser-client";
import { cowConsole } from "../../../util/cowConsole";
import {
  OnpageWidgetContext,
  OnpageWidgetEvent,
  UserAccessStatus,
} from "./types";

export function isPaymentRequired(
  _: OnpageWidgetContext,
  ev: OnpageWidgetEvent
) {
  if (ev.type !== "DONE_TAB_ADD" && ev.type !== "DONE_TAB_FETCH") {
    throw new InvalidEventTypeError(ev.type);
  }
  return ev.data.tab?.status === TabStatus.Full;
}
export function isUserAuthorized(ctx: OnpageWidgetContext) {
  return !!ctx.userAuthorized;
}
export function isUserAuthorizedAndIsSubscriptions(
  ctx: OnpageWidgetContext,
  ev: OnpageWidgetEvent
) {
  return (
    isUserAuthorized(ctx) &&
    ev.type === "PICK_OFFERING" &&
    ((ev.data.offering.salesModel as unknown) === "subscription" ||
      ev.data.offering.paymentModel === PaymentModel.NowSubscription)
  );
}
export function isSubscriptionOffering(
  _: OnpageWidgetContext,
  ev: OnpageWidgetEvent
) {
  return (
    ev.type === "DONE_PAYMENT" &&
    (ev.data.tab?.paymentModel === PaymentModel.NowSubscription ||
      ev.data.tab?.paymentModel === PaymentModel.Now)
  );
}
export function hasSelectedOffering(ctx: OnpageWidgetContext) {
  return !!ctx.selectedOffering;
}
export function hasTab(ctx: OnpageWidgetContext) {
  return !!ctx.tab;
}
// This guard is used to determine if an item has been added to the tab.
// The item is considered added if a purchase was attempted and either:
// - the purchaseStatus is 'Added'  (tab is still below the limit) or
// - the purchaseStatus is 'Pending' AND the tab was paid (purchase brought the tab above the limit)
export function wasItemAdded(ctx: OnpageWidgetContext) {
  return (
    ctx.purchaseStatus === PurchaseStatus.Added ||
    (ctx.purchaseStatus === PurchaseStatus.Pending && !!ctx.tabPaid)
  );
}
export function hasTabInDifferentCurrency(
  ctx: OnpageWidgetContext,
  ev: OnpageWidgetEvent
) {
  if (ev.type !== "DONE_TAB_FETCH") {
    throw new InvalidEventTypeError(ev.type);
  }
  return !!ev.data?.tab && ev.data?.tab?.currency !== ctx.currency.isoCode;
}
export function hasAccess(_: OnpageWidgetContext, ev: OnpageWidgetEvent) {
  if (ev.type !== "DONE_ACCESS_CHECK") {
    throw new InvalidEventTypeError(ev.type);
  }
  return ev.data.userAccessStatus === UserAccessStatus.ACCESS_GRANTED;
}

export function hasSubscription(_: OnpageWidgetContext, ev: OnpageWidgetEvent) {
  if (ev.type !== "DONE_ACCESS_CHECK") {
    throw new InvalidEventTypeError(ev.type);
  }
  return !!ev.data.userAccessDetails?.[0]?.subscriptionId;
}

export function shouldAlwaysGrantAccess() {
  if ((window as any).__SUPERTAB_DEBUG__?.alwaysGrantAccess) {
    cowConsole.log(
      "Skipping access check, because `window__SUPERTAB__.alwaysGrantAccess` is truthy."
    );
    return true;
  } else {
    return false;
  }
}
export function shouldAlwaysDenyAccess() {
  if ((window as any).__SUPERTAB_DEBUG__?.alwaysDenyAccess) {
    cowConsole.log(
      "Skipping access check, because `window__SUPERTAB__.alwaysDenyAccess` is truthy."
    );
    return true;
  } else {
    return false;
  }
}
export function isGtmEnabled(ctx: OnpageWidgetContext) {
  return !!ctx.gtmContainerId;
}
export function isSimplifiedFlow(ctx: OnpageWidgetContext) {
  return ctx.checkoutType === "simplified";
}

export function shouldSkipInitialAccessCheck(ctx: OnpageWidgetContext) {
  return !!ctx.skipInitialAccessCheck;
}

class InvalidEventTypeError extends Error {
  constructor(eventType: string) {
    super(
      `InvalidEventTypeError: '${eventType}' is not supported by this guard.`
    );
    return this;
  }
}
