import {
  ClientConfig,
  CurrencyResponse,
  ItemOffering,
  LaterpayBrowserClient,
  PurchaseStatus,
  Subscription,
  Tab,
} from "../../../tapper-browser-client";
import { transformSnakeObjToCamel } from "../../../tapper-browser-client/util";
import { cowConsole } from "../../../util/cowConsole";
import { timestampInMilliseconds } from "../../util/misc";
import { UserAccessDetail, UserAccessStatus } from "../onpageWidget";
import { ApiMachineContext, ApiMachineEvents } from "./types";

export async function fetchClientConfig(
  ctx: ApiMachineContext,
  ev: ApiMachineEvents
): Promise<{
  clientConfig: ClientConfig;
  currency: CurrencyResponse;
}> {
  cowConsole.log("Starting to fetch client config");
  const client = new LaterpayBrowserClient(
    ctx.clientId,
    ctx.authBaseUrl,
    ctx.ssoBaseUrl,
    ctx.tapiBaseUrl,
    ctx.refreshToken,
    ctx.accessToken
  );

  const currencyIsoCode =
    ev.type === "FETCH_CLIENT_CONFIG" ? ev.data.currency : undefined;
  const clientConfig = await client.fetchClientConfig(currencyIsoCode);
  const currency = await client.getCurrency(
    currencyIsoCode ?? clientConfig.suggestedCurrency
  );

  return { clientConfig, currency };
}

export async function checkAccess(ctx: ApiMachineContext): Promise<{
  userAccessStatus: UserAccessStatus;
  userAccessValidTo?: Date;
  userAccessDetails: UserAccessDetail[];
}> {
  cowConsole.log("Starting 'checkAccess' service", ctx);
  if (!ctx.accessToken) {
    throw new Error("Cannot check access without API access token.");
  }
  const contentKeys = ctx.clientConfig?.contentKeys;
  if (!contentKeys) {
    throw new Error("Cannot authorize client without content keys");
  }
  const client = new LaterpayBrowserClient(
    ctx.clientId,
    ctx.authBaseUrl,
    ctx.ssoBaseUrl,
    ctx.tapiBaseUrl,
    ctx.refreshToken,
    ctx.accessToken
  );
  const accessResponses = await Promise.all(
    contentKeys.map(({ contentKey }) =>
      client.checkAccess(contentKey!.toString())
    )
  );
  cowConsole.log({ accessResponses });
  const userAccessDetails: UserAccessDetail[] = transformSnakeObjToCamel(
    accessResponses
      .filter(({ access }) => access) // Make sure the access property is present
      .map(({ access }) => access)
  );

  // Find the validity date furthest in the future
  // (There is usually just one access detail at a time that grants access,
  // but there can be multiple in theory.)
  const userAccessValidTo = userAccessDetails
    .filter(({ status }) => status === "Granted")
    .map(({ validTo }) => new Date(timestampInMilliseconds(validTo)))
    .sort()
    .pop();

  const userAccessStatus = userAccessValidTo
    ? UserAccessStatus.ACCESS_GRANTED
    : UserAccessStatus.ACCESS_DENIED;

  return {
    userAccessStatus,
    userAccessValidTo,
    userAccessDetails,
  };
}

export async function fetchTab(
  ctx: ApiMachineContext
): Promise<{ tab: Tab | null }> {
  cowConsole.log("Fetching tab", ctx);
  const client = new LaterpayBrowserClient(
    ctx.clientId,
    ctx.authBaseUrl,
    ctx.ssoBaseUrl,
    ctx.tapiBaseUrl,
    ctx.refreshToken,
    ctx.accessToken
  );
  const latestTab = await client.fetchLatestTab();
  return {
    tab: latestTab ?? null,
  };
}

export async function fetchSubscription(
  ctx: ApiMachineContext,
  ev: ApiMachineEvents
): Promise<{ subscription: Subscription | null }> {
  cowConsole.log("Fetching subscription", ctx, ev);
  const client = new LaterpayBrowserClient(
    ctx.clientId,
    ctx.authBaseUrl,
    ctx.ssoBaseUrl,
    ctx.tapiBaseUrl,
    ctx.refreshToken,
    ctx.accessToken
  );

  if (ev.type !== "SUBSCRIPTION_FETCH") {
    throw new Error(
      "Cannot fetch subscription outside of SUBSCRIPTION_FETCH event context"
    );
  }

  if (!ev.data.subscriptionId) {
    throw new Error("Cannot fetch subscription if subscriptionId is not found");
  }

  const subscription = await client.fetchSubscription(ev.data.subscriptionId);
  return {
    subscription: subscription ?? null,
  };
}

export async function addToTab(
  ctx: ApiMachineContext,
  ev: ApiMachineEvents
): Promise<{
  tab: Tab;
  purchaseStatus: PurchaseStatus;
  offering: ItemOffering;
}> {
  const client = new LaterpayBrowserClient(
    ctx.clientId,
    ctx.authBaseUrl,
    ctx.ssoBaseUrl,
    ctx.tapiBaseUrl,
    ctx.refreshToken,
    ctx.accessToken
  );
  if (ev.type !== "TAB_ADD") {
    throw new Error("Cannot add item outside of ADD_TO_TAB event context");
  }
  if (!ev.data.selectedOffering) {
    throw new Error("Cannot add item to tab because no offering is selected");
  }
  const offering = ev.data.selectedOffering;
  const currency = ev.data.currency;
  const {
    tab,
    detail: { purchaseStatus },
  } = await client.purchaseItemOffering(offering.id!, currency);
  return { tab, purchaseStatus, offering };
}

export async function offpagePayment(
  ctx: ApiMachineContext,
  ev: ApiMachineEvents
): Promise<{ tab?: Tab; paymentCompleted?: false }> {
  const client = new LaterpayBrowserClient(
    ctx.clientId,
    ctx.authBaseUrl,
    ctx.ssoBaseUrl,
    ctx.tapiBaseUrl,
    ctx.refreshToken,
    ctx.accessToken
  );
  if (ev.type !== "INIT_PAYMENT") {
    throw new Error("Cannot start offpage payment outside payment context");
  }
  if (!ctx.checkoutBaseUrl) {
    throw new Error("Cannot start offpage payment without checkout base url");
  }
  if (!ev.data.tab) {
    throw new Error("Cannot start offpage payment without tab");
  }

  const url = new URL(ctx.checkoutBaseUrl);
  url.searchParams.set("language", ctx.localeCode);

  const tab = ev.data.tab;

  return client.startOffpagePayment(
    url,
    tab.id,
    ctx.clientConfig?.testMode || tab.testMode,
    ev.data.checkoutWindow ?? null
  );
}
