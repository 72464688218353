import { assign } from "xstate";
import { ApiMachineContext, ApiMachineEvents } from "./types";
import { sendParent } from "xstate/lib/actions";

export const assignClientConfig = assign<
  ApiMachineContext,
  ApiMachineEvents,
  ApiMachineEvents
>((_, ev) =>
  ev.type === "done.invoke.fetchClientConfig"
    ? {
        clientConfig: ev.data.clientConfig,
        currency: ev.data.currency,
      }
    : {}
);

export const sendClientConfig = sendParent<
  ApiMachineContext,
  ApiMachineEvents,
  ApiMachineEvents
>((_, ev) => ({
  type: "DONE_CLIENT_CONFIG",
  ...(ev.type === "done.invoke.fetchClientConfig" && {
    data: ev.data,
  }),
}));

export const assignTokens = assign<
  ApiMachineContext,
  ApiMachineEvents,
  ApiMachineEvents
>((_, ev) =>
  ev.type === "LOGGED_IN" || ev.type === "REFRESHED_TOKENS"
    ? {
        accessToken: ev.accessToken,
        refreshToken: ev.refreshToken,
      }
    : {}
);

export const removeTokens = assign<
  ApiMachineContext,
  ApiMachineEvents,
  ApiMachineEvents
>((_, ev) =>
  ev.type === "LOGGED_OUT"
    ? {
        accessToken: undefined,
        refreshToken: undefined,
      }
    : {}
);

export const sendAccessResult = sendParent<
  ApiMachineContext,
  ApiMachineEvents,
  ApiMachineEvents
>((_, ev) => ({
  type: "DONE_ACCESS_CHECK",
  ...(ev.type === "done.invoke.checkAccess" && {
    data: {
      userAccessStatus: ev.data.userAccessStatus,
      userAccessValidTo: ev.data.userAccessValidTo,
      userAccessDetails: ev.data.userAccessDetails,
    },
  }),
}));

export const sendTab = sendParent<
  ApiMachineContext,
  ApiMachineEvents,
  ApiMachineEvents
>((_, ev) => ({
  type: "DONE_TAB_FETCH",
  data: {
    ...(ev.type === "done.invoke.fetchTab" && {
      tab: ev.data.tab ?? undefined,
    }),
  },
}));

export const sendSubscription = sendParent<
  ApiMachineContext,
  ApiMachineEvents,
  ApiMachineEvents
>((_, ev) => ({
  type: "DONE_SUBSCRIPTION_FETCH",
  data: {
    ...(ev.type === "done.invoke.fetchSubscription" && {
      subscription: ev.data.subscription ?? undefined,
    }),
  },
}));

export const sendAddToTab = sendParent<
  ApiMachineContext,
  ApiMachineEvents,
  ApiMachineEvents
>((_, ev) => ({
  type: "DONE_TAB_ADD",
  ...(ev.type === "done.invoke.addToTab" && {
    data: {
      tab: ev.data.tab,
      purchaseStatus: ev.data.purchaseStatus,
      offering: ev.data.offering,
    },
  }),
}));

export const sendTabPaid = sendParent<
  ApiMachineContext,
  ApiMachineEvents,
  ApiMachineEvents
>((_, ev) => ({
  type: "DONE_PAYMENT",
  ...(ev.type === "done.invoke.offpagePayment" && {
    data: {
      tab: ev.data.tab,
    },
  }),
}));

export const sendError = sendParent<
  ApiMachineContext,
  ApiMachineEvents,
  ApiMachineEvents
>((_, ev) => ({
  type: "API_ERROR",
  ...(ev.type.includes("error.platform") && {
    data: {
      // @ts-ignore
      message: ev?.data?.message,
    },
  }),
}));
