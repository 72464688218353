import {
  ActionFunction,
  ActorRefFrom,
  EventFrom,
  SendExpr,
  actions,
  assign,
  sendTo,
  spawn,
} from "xstate";
import { sendErrorToSentry } from "../../../capture-errors";
import AuthenticationMachine, {
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
} from "../auth";

import ApiMachine from "../api";
import { ApiMachineContext, ApiMachineEvents } from "../api/types";
import LeaveBehindWidgetMachine from "../leavebehindWidget";
import {
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents,
} from "../leavebehindWidget/types";
import {
  BackToCallerEventDetail,
  OnpageWidgetContext,
  OnpageWidgetEvent,
  UserAccessStatus,
} from "./";
import { isUserAuthorized, wasItemAdded } from "./guards";
import { environment } from "../../../environment";

export const spawnAuthMachine = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>({
  authMachineRef: (ctx) =>
    spawn<AuthenticationMachineContext, AuthenticationMachineEvents>(
      AuthenticationMachine.withContext({
        clientId: ctx.merchant.clientId,
        redirectUri: ctx.clientConfig!.redirectUri,
        authBaseUrl: ctx.api.authBaseUrl,
        authUrl: ctx.api.authUrl,
        tokenUrl: ctx.api.tokenUrl,
        ssoBaseUrl: ctx.api.ssoBaseUrl,
        tapiBaseUrl: ctx.api.tapiBaseUrl,
        localeCode: ctx.localeCode,
      }),
      {
        name: "authentication",
        sync: false,
      }
    ),
});

export const spawnApiMachine = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>({
  apiMachineRef: (ctx) =>
    spawn<ApiMachineContext, ApiMachineEvents>(
      ApiMachine.withContext({
        clientId: ctx.merchant.clientId,
        tapiBaseUrl: ctx.api.tapiBaseUrl,
        ssoBaseUrl: ctx.api.ssoBaseUrl,
        authBaseUrl: ctx.api.authBaseUrl,
        authUrl: ctx.api.authUrl,
        tokenUrl: ctx.api.tokenUrl,
        checkoutBaseUrl: ctx.api.checkoutBaseUrl,
        localeCode: ctx.localeCode,
      }),
      {
        name: "api",
        sync: false,
      }
    ),
});

export const spawnLeaveBehindMachine = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent
>((ctx) =>
  ctx.authMachineRef && !ctx.leaveBehindMachineRef
    ? assign({
        leaveBehindMachineRef: spawn<
          LeaveBehindMachineContext,
          LeaveBehindMachineEvents
        >(
          LeaveBehindWidgetMachine.withContext({
            apiMachineRef: ctx.apiMachineRef,
            authMachineRef: ctx.authMachineRef,
            ...ctx,
          }),
          {
            name: "leavebehindWidget",
            sync: true,
          }
        ),
      })
    : undefined
);

const sendToAuth = (event: EventFrom<typeof AuthenticationMachine>) =>
  sendTo<
    OnpageWidgetContext,
    OnpageWidgetEvent,
    ActorRefFrom<typeof AuthenticationMachine>
  >("authentication", event);

const sendToLBW = (ctx: OnpageWidgetContext, event: LeaveBehindMachineEvents) =>
  ctx.leaveBehindMachineRef
    ? sendTo<
        OnpageWidgetContext,
        OnpageWidgetEvent,
        ActorRefFrom<typeof LeaveBehindWidgetMachine>
      >("leavebehindWidget", event)
    : "";

const sendToApi = (
  event:
    | EventFrom<typeof ApiMachine>
    | SendExpr<
        OnpageWidgetContext,
        OnpageWidgetEvent,
        EventFrom<typeof ApiMachine>
      >
) => sendTo("api", event);

export const startAuth = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((_, ev) =>
  ev.type === "LOG_IN"
    ? sendToAuth({
        type: "START_AUTH",
        screenHint: "login",
        loginAction: ev.loginAction ?? undefined,
      })
    : sendToAuth({
        type: "START_AUTH",
        screenHint: "register",
        loginAction: "add_to_tab",
      })
);

export const checkAccess = sendToApi({ type: "ACCESS_CHECK" });
export const fetchTab = sendToApi({ type: "TAB_FETCH" });
export const fetchSubscription = sendToApi((ctx) => ({
  type: "SUBSCRIPTION_FETCH",
  data: {
    subscriptionId: ctx.userAccessDetails[0].subscriptionId ?? undefined,
  },
}));

export const fetchClientConfig = sendToApi((ctx) => ({
  type: "FETCH_CLIENT_CONFIG",
  data: { currency: ctx.tab?.currency },
}));

export const initPayment = sendToApi((ctx) => ({
  type: "INIT_PAYMENT",
  data: { tab: ctx.tab, checkoutWindow: ctx.checkoutWindow },
}));

export const addToTab = sendToApi((ctx) => ({
  type: "TAB_ADD",
  data: {
    selectedOffering: ctx.selectedOffering,
    currency: ctx.currency.isoCode,
  },
}));

export const assignCheckoutWindow = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>(() => ({
  checkoutWindow: window.open("", "supertabCheckout"),
}));

export const unAssignCheckoutWindow = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx) => {
  if (!ctx.checkoutWindow?.closed) {
    ctx.checkoutWindow?.close();
  }

  return assign({
    checkoutWindow: null,
  });
});

export const sendTab = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) =>
  ev.type === "DONE_TAB_FETCH" ||
  ev.type === "DONE_TAB_ADD" ||
  ev.type === "DONE_PAYMENT"
    ? sendToLBW(ctx, ev)
    : undefined
);

export const sendSubscription = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) =>
  ev.type === "DONE_SUBSCRIPTION_FETCH" ? sendToLBW(ctx, ev) : undefined
);

export const sendLoggedIn = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) =>
  ev.type === "LOGGED_IN" ? [sendToApi(ev), sendToLBW(ctx, ev)] : undefined
);

export const sendLoggedOut = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) =>
  ev.type === "LOGGED_OUT" ? [sendToApi(ev), sendToLBW(ctx, ev)] : undefined
);

export const sendAuthFailed = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) =>
  ev.type === "AUTH_FAILED" ? [sendToApi(ev), sendToLBW(ctx, ev)] : undefined
);

export const sendTokens = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((_, ev) => (ev.type === "REFRESHED_TOKENS" ? sendToApi(ev) : undefined));

export const sendError = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) => (ev.type === "API_ERROR" ? sendToLBW(ctx, ev) : undefined));

export const checkAuth = sendToAuth({ type: "CHECK_AUTH" });
export const cancelAuth = sendToAuth({ type: "CANCEL_AUTH" });
export const logOut = sendToAuth({ type: "LOG_OUT" });

export const dispatchBackToCaller: ActionFunction<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
> = (ctx) => {
  const eventNames = ["cow.event", environment.widgetCustomEventName];

  eventNames.forEach((eventName) => {
    window.dispatchEvent(
      new CustomEvent<BackToCallerEventDetail>(eventName, {
        detail: {
          type: "back_to_caller",
          accessStatus: ctx.userAccessStatus,
          accessDetails: ctx.userAccessDetails,
          accessValidTo: ctx.userAccessValidTo,
          itemAdded: wasItemAdded(ctx) ? ctx.selectedOffering : undefined,
          tabPaid: ctx.tabPaid,
          currencyDetail: ctx.currency
            ? {
                isoCode: ctx.currency.isoCode,
                baseUnit: ctx.currency.baseUnit,
              }
            : undefined,
        },
      })
    );
  });
};

export const assignClientConfig = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) => {
  if (ev.type === "DONE_CLIENT_CONFIG") {
    return [
      assign({
        clientConfig: ev.data.clientConfig,
        currency: ev.data.currency,
        defaultTabLimit: ev.data.currency.tabLimit,
      }),
      sendToLBW(ctx, ev),
    ];
  }
});

export const assignUserAuthorizedStatus = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((_, ev) =>
  ev.type === "LOGGED_IN" ||
  ev.type === "LOGGED_OUT" ||
  ev.type === "AUTH_FAILED"
    ? { userAuthorized: ev.type === "LOGGED_IN" }
    : {}
);

export const assignAccessGranted = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>(() => ({
  userAccessStatus: UserAccessStatus.ACCESS_GRANTED,
}));

export const assignAccessChecking = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>(() => ({
  userAccessStatus: UserAccessStatus.CHECKING,
}));

export const assignSubscription = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((_, ev) =>
  ev.type === "DONE_SUBSCRIPTION_FETCH"
    ? {
        subscription: ev.data.subscription ?? undefined,
      }
    : {}
);

export const assignIsSubscriptionEnabled = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) =>
  ev.type === "TOGGLE_SUBSCRIPTIONS"
    ? {
        isSubscriptionEnabled: !ctx.isSubscriptionEnabled,
        discount: ev.data.discount,
      }
    : {}
);

export const resetIsSubscriptionEnabled = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>(() => ({
  isSubscriptionEnabled: false,
}));

export const assignAccessDenied = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>(() => ({
  userAccessStatus: UserAccessStatus.ACCESS_DENIED,
}));

export const assignAccessUnauthorized = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>(() => ({
  userAccessStatus: UserAccessStatus.CLIENT_UNAUTHORIZED,
}));

export const assignAccessError = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>(() => ({
  userAccessStatus: UserAccessStatus.ERROR,
}));

export const assignCheckAccessResult = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((_, ev) =>
  ev.type === "DONE_ACCESS_CHECK"
    ? {
        userAccessStatus: ev.data.userAccessStatus,
        userAccessDetails: ev.data.userAccessDetails,
        userAccessValidTo: ev.data.userAccessValidTo,
      }
    : {}
);

export const sendCheckAccessResult = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) =>
  ev.type === "DONE_ACCESS_CHECK"
    ? sendToLBW(ctx, {
        ...ev,
        data: { ...ev.data, itemAdded: wasItemAdded(ctx) },
      })
    : undefined
);

export const removeSelectedOffering = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>(() => ({
  selectedOffering: undefined,
  selectedOfferingAnonymously: undefined,
}));

export const assignPurchaseStatus = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((_, ev) => {
  if (ev.type !== "DONE_TAB_ADD") {
    throw new InvalidEventTypeError(ev.type);
  }
  return {
    purchaseStatus: ev.data.purchaseStatus,
  };
});

export const resetPurchaseAndPaymentData = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((_, ev) => ({
  selectedOffering: undefined,
  selectedOfferingAnonymously: undefined,
  purchaseStatus: undefined,
  tabPaid: undefined,
}));

export const assignTab = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((_, ev) => {
  if (ev.type !== "DONE_TAB_FETCH" && ev.type !== "DONE_TAB_ADD") {
    throw new InvalidEventTypeError(ev.type);
  }
  return {
    tab: ev.data.tab ?? undefined,
  };
});

export const assignOffering = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) =>
  ev.type === "PICK_OFFERING"
    ? {
        selectedOffering: ev.data.offering,
        selectedOfferingAnonymously: !isUserAuthorized(ctx),
      }
    : {}
);

export const assignCheapestOffering = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx) => {
  return {
    selectedOffering: ctx.clientConfig?.offerings?.[0],
  };
});

export const assignError = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) =>
  ev.type === "API_ERROR"
    ? {
        api: {
          ...ctx.api,
          error: {
            message: ev.data.message,
            code: "API_ERROR",
          },
        },
      }
    : {}
);

export const trackError: ActionFunction<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
> = (_, ev: any) => {
  console.error(ev);
  if (ev.data) {
    sendErrorToSentry(ev.data);
  }
};

export const assignTabPaid = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((_, ev) =>
  ev.type === "DONE_PAYMENT"
    ? {
        tab: undefined,
        tabPaid: ev.data.tab,
      }
    : {}
);

export const assignAuthFailed = assign<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) =>
  ev.type === "AUTH_FAILED"
    ? {
        // Ensure that once this flag is true it cannot be reset to false.
        // This is required in order to persist the flag even if auth fails
        // multiple times.
        loggedOutBecauseOfAnError:
          ctx.loggedOutBecauseOfAnError || ev.userWasLoggedIn,
      }
    : {}
);

export const hideLeaveBehind = actions.pure<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
>((ctx, ev) => sendToLBW(ctx, { type: "HIDE" }));

export const acceptBackawayOffer: ActionFunction<
  OnpageWidgetContext,
  OnpageWidgetEvent,
  OnpageWidgetEvent
> = (ctx) => ctx.backawayOffer?.onAccept();

class InvalidEventTypeError extends Error {
  constructor(eventType: string) {
    super(
      `InvalidEventTypeError: '${eventType}' is not supported by this action.`
    );
    return this;
  }
}
