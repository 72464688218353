import BottomSheet from "../../organisms/BottomSheet";
import { InfoBody } from "./styled";
import { TabSummary } from "../../../types";
import { Trans } from "../../../util/i18n";
import BackButton from "../../atoms/BackButton";

const TSTabInfo = ({
  testMode,
  currency,
  limit,
  onBack,
}: { onBack: () => void; testMode?: boolean } & TabSummary) => {
  return (
    <BottomSheet
      testId="info-sheet"
      testMode={testMode}
      currency={currency.isoCode}
    >
      <BackButton onBack={onBack} />
      <InfoBody variant="compact">
        <Trans i18nKey="tabInfoInstructionsTitle" parent="h1" />
        <Trans i18nKey="tabInfoInstructions" values={{ limit, currency }} />
      </InfoBody>
    </BottomSheet>
  );
};

export default TSTabInfo;
