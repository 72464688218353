import AuthenticationMachine, { AuthenticationMachineEvents } from "../auth";
import { ActorRefFrom, InterpreterFrom, ActionTypes } from "xstate";
import {
  ClientConfig,
  ItemOffering,
  Tab,
  User,
  LoginAction,
  Merchant,
  CurrencyResponse,
  Subscription,
  PurchaseStatus,
} from "../../../tapper-browser-client";

import OnpageWidgetMachine from "./";

import { BackawayProps, CowCustomizations } from "../../types";
import ApiMachine from "../api";
import { ApiMachineEvents } from "../api/types";
import LeaveBehindWidgetMachine from "../leavebehindWidget";

export interface OnpageWidgetContext {
  merchant: Merchant;
  clientConfig?: ClientConfig;
  currency: CurrencyResponse;
  defaultTabLimit: number;
  selectedOffering?: ItemOffering;
  selectedOfferingAnonymously?: boolean;
  tab?: Tab;
  purchaseStatus?: PurchaseStatus;
  userAccessStatus: UserAccessStatus;
  userAccessDetails: UserAccessDetail[];
  userAccessValidTo?: Date;
  tabPaid?: Tab;
  subscription?: Subscription;
  isSubscriptionEnabled: boolean;
  discount: number;
  userAuthorized: boolean;
  api: {
    tapiBaseUrl: string;
    authBaseUrl: string;
    authUrl?: string;
    tokenUrl?: string;
    ssoBaseUrl: string;
    checkoutBaseUrl: string;
    userPortalBaseUrl: string;
    error?: {
      message: string;
      code: string;
    };
  };
  loggedOutBecauseOfAnError?: boolean;
  backawayOffer?: BackawayProps;
  customization?: CowCustomizations;
  user?: User;
  authMachineRef?: ActorRefFrom<typeof AuthenticationMachine>;
  apiMachineRef?: ActorRefFrom<typeof ApiMachine>;
  leaveBehindMachineRef?: ActorRefFrom<typeof LeaveBehindWidgetMachine>;
  gtmContainerId?: string;
  gtmEnvironment?: Record<string, any>;
  gtmDataLayerName?: string;
  skipInitialAccessCheck?: boolean;
  localeCode: string;
  checkoutType: "simplified" | "normal";
  checkoutWindow?: Window | null;
}

export type OnpageWidgetService = InterpreterFrom<typeof OnpageWidgetMachine>;

export type OnpageWidgetEvent =
  | { type: ActionTypes.Init }
  | { type: ActionTypes.Update }
  | { type: "CONTRIBUTE" }
  | {
      type: "TOGGLE_SUBSCRIPTIONS";
      data: { isSubscriptionEnabled: boolean; discount: number };
    }
  | { type: "SUBSCRIPTION_FETCH"; data: { subscriptionId?: string } }
  | { type: "CANCEL" }
  | { type: "SHOW_INFO" }
  | { type: "SHOW_TAB_DETAILS" }
  | { type: "BACK" }
  | { type: "ACCEPT_BACKAWAY_OFFER" }
  | { type: "HIDE" }
  | { type: "RETRY" }
  | {
      type: "PICK_OFFERING";
      data: { offering: ItemOffering };
    }
  | {
      type: "START_OFFPAGE_PAYMENT";
    }
  | {
      type: "CHECKOUT";
    }
  | {
      type: "PAY_ONPAGE";
    }
  | {
      type: "PAYMENT_COMPLETED";
    }
  | {
      type: "DONE_SUBSCRIPTION_FETCH";
      data: {
        subscription?: Subscription | null;
      };
    }
  | {
      type: "LOG_IN";
      loginAction: LoginAction;
    }
  | {
      type: "SIGN_UP";
    }
  | { type: "CHECK_ACCESS" }
  | AuthenticationMachineEvents
  | ApiMachineEvents;

export enum UserAccessStatus {
  UNKNOWN = "unknown",
  CHECKING = "checking",
  ACCESS_GRANTED = "access_granted",
  ACCESS_DENIED = "access_denied",
  CLIENT_UNAUTHORIZED = "client_unauthorized",
  ERROR = "error",
}

export interface UserAccessDetail {
  validFrom: number;
  validTimedelta: string;
  validTo: number;
  createdAt: number;
  contentKey: string;
  merchantId: string;
  userId: string;
  offeringId: string;
  purchaseId: string;
  subscriptionId?: string;
  status: string;
}

export interface BackToCallerEventDetail {
  type: "back_to_caller";
  accessStatus: UserAccessStatus;
  accessDetails: UserAccessDetail[];
  accessValidTo?: Date;
  itemAdded?: ItemOffering;
  tabPaid?: Tab;
  currencyDetail?: {
    isoCode: string;
    baseUnit: number;
  };
}
